import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import MobileMenuButton from './mobileMenuButton';

import * as containerStyles from "../styles/buttonAppBar.module.css";

export default function ButtonAppBar() {



  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>

              <nav className={containerStyles.mobileMenu}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    Khao Manee
                  </Grid>
                  <Grid item>
                    <MobileMenuButton />
                  </Grid>
                </Grid>
              </nav>

              <nav className={containerStyles.desktopMenu}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                  <Grid item>Khao Manee</Grid>
                  <Grid item>
                    <Stack direction="row" spacing={8} style={{fontSize: "14px"}}>
                      <div>Tokenomics</div>
                      <div>Roadmap</div>
                      <div>Khoa App</div>
                      <div>Social</div>
                    </Stack>
                  </Grid>
                  <Grid item><Button color="inherit">Go to App</Button></Grid>
                </Grid>
              </nav>
          
        </Toolbar>
      </AppBar>
    </Box>
  );
}
